import './App.css';

function Uribo() {
    return (
        <div className="App">
            <header className="App-header">
                <div>
                    <img src="https://media.discordapp.net/stickers/879321323971674163.webp?size=4096" alt="dieduribo" className={"rainboeshadoowBOXMOMENT"}></img><br/>
                </div>
            </header>
        </div>
    );
}

export default Uribo;
